import * as React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location}>
      <SEO title="404: Not Found"  location={location.href}/>
      <div className="container">
        <div className="row justify-content-center align-items-center" style={{minHeight: "60vh"}}>
          <div className="col-xxl-8 col-xl-8  col-lg-8  col-md-12 col-sm-12 text-center">
            <h1>404: Not Found</h1>
            <p style={{marginTop: 36}}>You just hit a route that doesn&#39;t exist... the sadness.</p>
            <Link class="btn btn-fill btn-medium btn-load-more" style={{marginTop: 36}} to="/">Go home!</Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
